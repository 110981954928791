<template>
	<section class="search-result">
    <div class="search-result-head">
      Search Results
    </div>

    <div class="search-result-cat-row flex-row align-center just-start">
      <div class="search-result-cat-item active">
        Movies (171)
      </div>
      <div class="search-result-cat-item">
        TV Shows (0)
      </div>
      <div class="search-result-cat-item">
        Person  (0)
      </div>
    </div>


    <div class="searchresult-content-row flex-col ">
      <div class="searchresult-content-item flex-row align-center just-start" v-for="i in 10" :key="i">
        <div class="searchresult-content-poster">
          <img src="https://www.themoviedb.org/t/p/w180_and_h180_face/hTMEa1eaSLBr9VJcoiXuU89MK81.jpg" alt="">
        </div>
        <div class="searchresult-content-info-row flex-col align-start just-center">
          <div class="searchresult-content-title">
            Liu Yifei
          </div>
          <div class="searchresult-content-info-date">
            October 27, 2021
          </div>
          <div class="searchresult-content-info-descrip">
            During the Tang Dynasty, Emperor Xuan (Jiang Hua) fell in love with the beauty of Yang Guifei (Shang Hai Lan) is an orphan. was a famous woman in those days and also a wise man is the concubine that Hong Tae loves the most The love  During the Tang Dynasty, Emperor Xuan (Jiang Hua) fell in love with the beauty of Yang Guifei (Shang Hai Lan) is an orphan. was a famous woman in those days and also a wise man is the concubine that Hong Tae loves the most The love During the Tang Dynasty, Emperor Xuan (Jiang Hua) fell in love with the beauty of Yang Guifei (Shang Hai Lan) is an orphan. was a famous woman in those days and also a wise man is the concubine that Hong Tae loves the most The love
          </div>
        </div>
      </div>
    </div>



  </section>
</template>

<script>
import '@/css/home/pc/search-result.scss'
export default {
	data() {
		return {}
	},
	props: {
		appname: {
			default: ' ',
		},
	},
	methods: {},
}
</script>
